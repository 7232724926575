import request from '@/utils/request.js'
//查询
export function getlistPage(params) {
    return request({
        url:'/api/third/wms/storageInit',
        method:'get',
        params
      
    });
}

export function gettableList(id) {
    return request({
        url:'/api/third/wms/storage/list/' + id,
        method:'get',
    });
}
export function getSku(params) {
    return request({
        url:'/api/third/wms/product/wmsProductListPage',
        method:'get',
        params
      
    });
}

export function changetatus(data) {
    return request({
        url:'/api/third/wms/product/productMatch',
        method:'post',
        data
    });
}
export function getlocalList(params) {
    return request({
        url:'/api/third/wms/product/localProductListPage',
        method:'get',
        params
      
    });
}
export function detail(id) {
    return request({
        url:'/api/third/wms/detail/' + id,
        method:'get',
    });
}
export function autoMatch(data) {
    return request({
        url:'/api/third/wms/product/autoMatch',
        method:'post',
        data
    });
}

export function wmsStorageInit(params) {
    return request({
        url:'/api/third/wms/wmsStorageInit',
        method:'get',
        params
    });
}

export function add(data) {
    return request({
        url:'/api/third/wms/add',
        method:'post',
        data
    });
}
//修改状态
export function updateStatus(data) {
    return request({
      url: "/api/third/wms/storage/updateStatus",
      data,
      method: "put"
    })
  }

  export function updateEdit(data) {
    return request({
      url: "/api/third/wms/storage/update",
      data,
      method: "put"
    })
  }

  export function updateSynStatus(data) {
    return request({
        url:'/api/third/wms/updateSynStatus',
        method:'post',
        data
    });
}

export function delType(id){
    return request({
        method:'delete',
        url:`/api/third/wms/delete/${id}`
    });
}