<template>
  <div class="content">
    <div class="left">
      <div
        style="
          display: flex;
          height: 35px;
          align-items: center;
          padding: 0px 20px 12px 20px;
        "
      >
        <div style="font-size: 14px; color: #888">仓库</div>
        <div style="margin-left: 60px">
          <el-button type="text" @click="addStor">添加仓库</el-button>
        </div>
      </div>
      <div style="width: 180px; float: left; margin: 5px 0 10px 8px">
        <el-input
          placeholder="搜索"
          suffix-icon="el-icon-search"
          v-model="input"
        >
        </el-input>
      </div>
      <div style="float: left; width: 100%">
        <el-menu
          default-active="2"
          class="el-menu-vertical-demo"
          @open="handleOpen"
          @close="handleClose"
          active-text-color="#006eff"
        >
          <el-submenu index="1">
            <template slot="title">
              <span style="font-weight: 600; font-size: 14px">本地仓</span>
            </template>
            <el-menu-item-group>
              <el-menu-item
                :index="item.id"
                v-for="(item, index) in localStorageList"
                :key="index"
                @click="handleClick(item)"
                >{{ item.storageName }}</el-menu-item
              >
            </el-menu-item-group>
          </el-submenu>
          <el-submenu index="2">
            <template slot="title">
              <span style="font-weight: 600; font-size: 14px">海外仓</span>
            </template>
            <el-menu-item-group>
              <el-menu-item
                :index="item.wmsProviderId"
                v-for="(item, index) in outerStorageList"
                :key="index"
                @click="handleClick1(item.wmsProviderId)"
                >{{ item.dicItemValue }}</el-menu-item
              >
            </el-menu-item-group>
          </el-submenu>
        </el-menu>
      </div>
    </div>
    <div class="right">
      <div class="top">
        <div class="top_title">{{ leftdetail.storageName }}</div>
      </div>
      <div v-show="top">
        <div style="margin-bottom: 30px; margin-top: 20px">
          <span class="pp"> 基本信息</span>
        </div>

        <!-- <div class="tips">
          <span> 关联供应商 </span
          ><span class="oo">{{ leftdetail.contact }}</span>
        </div> -->
        <div class="tips">
          <span>负责人 </span><span class="oo">{{ leftdetail.contact }}</span>
        </div>
        <div class="tips">
          <span>电话 </span><span class="oo">{{ leftdetail.tel }}</span>
        </div>
        <div class="tips">
          <span>地址</span><span class="oo"> {{ leftdetail.address }}</span>
        </div>
        <div class="tips">
          <span>备注 </span><span class="oo">{{ leftdetail.tips }}</span>
        </div>
      </div>
      <div v-show="tops">
        <div style="margin-bottom: 30px; margin-top: 20px">
          <span class="pp"> 基本信息</span><el-button type="text" icon="el-icon-edit" @click="editinfo" style="width:36px"></el-button>
        </div>
        <div class="tips">
          <span>授权状态</span>
          <span class="oo">{{ rightDetail.authStatus == 1 ? '授权成功' : '未授权' }}</span>
            <el-button
            style="    margin-left: -69px;margin-top: -10px;"
              type="text"
              v-if="rightDetail.authStatus == 1"
              @click="cancelstatus()">取消授权</el-button>
        </div>
        <div class="tips">
          <span> 同步状态 </span>
          <div
                  style="
                    display: flex;
                    justify-content: space-around;
                    align-items: center;
                  "
                >
                  <el-switch
                    style="display: block"
                    v-model="rightDetail.synStatus"
                    :active-value="Number(1)"
                    :inactive-value="Number(0)"
                    @change="changeStatu(rightDetail.synStatus)"
                  >
                  </el-switch>
                </div>
        </div>
        <div class="tips">
          <span>授权人员 </span
          ><span class="oo">{{ rightDetail.createPerson }}</span>
        </div>
        <div class="tips">
          <span>授权时间</span
          ><span class="oo"> {{ rightDetail.createTime }}</span>
        </div>
      </div>
      <div v-show="bottom" style="height: 100%">
        <p class="msg">仓库信息</p>
        <el-tabs v-model="activeName" @tab-click="handleClickss">
          <el-tab-pane label="仓库设置" name="first"> </el-tab-pane>
          <el-tab-pane label="SKU配对" name="second"> </el-tab-pane>
        </el-tabs>
        <p class="shuoming" v-if="activeName == 'first'">
          启用仓库后，第三方仓服务商仓库数据同步到仓颉系统，并在仓颉系统维护服务商仓库信息。
        </p>
        <div class="table" v-if="activeName == 'first'">
          <el-table
            height="100%"
            :data="tableData"
            style="width: 100%"
            cell-style="text-align:center"
            header-cell-style="text-align:center;background:#f7f8fa"
            :show-overflow-tooltip="true"
          >
            <el-table-column
              prop="wmsStorageName"
              label="第三方仓仓库名称"
              width="width"
            >
            </el-table-column>
            <el-table-column
              prop="wmsStorageCode"
              label="第三方仓库代码"
              width="width"
            >
            </el-table-column>
            <el-table-column
              prop="systemStorageName"
              label="系统仓库名称"
              width="width"
            >
            </el-table-column>
            <el-table-column prop="country" label="国家/地区" width="width">
            </el-table-column>
            <el-table-column prop="isUsed" label="启用" width="width">
              <template slot-scope="scoped">
                <div
                  style="
                    display: flex;
                    justify-content: space-around;
                    align-items: center;
                    padding: 0 20px;
                  "
                >
                  <el-switch
                    style="display: block"
                    v-model="scoped.row.isUsed"
                    :active-value="Number(1)"
                    :inactive-value="Number(0)"
                    @change="changeStatus(scoped.row.id, scoped.row.isUsed)"
                  >
                  </el-switch>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="areaName" label="操作" width="width">
              <template slot-scope="scope">
                <el-button
                  :disabled="isUsed"
                  type="text"
                  style="font-size: 15px"
                  @click="changeMessage(scope.row.id)"
                  >编辑</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="sku" v-if="activeName == 'second'">
          <div class="onerow">
            <el-button @click="goodsImportedBox=true">导入配对</el-button>
            <el-button @click="automaticPair">系统自动配对</el-button>
            <div style="float: right">
              <el-select
                v-model="value"
                placeholder="请选择"
                style="margin-right: 8px"
              >
                <el-option
                  v-for="item in option"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
              <el-select
                v-model="skuObj.status"
                placeholder="配对状态"
                @change="search"
              >
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
              <div style="width: 300px; float: right; margin-left: 8px">
                <el-input
                  placeholder="搜索"
                  @keyup.enter.native="search"
                  suffix-icon="el-icon-search"
                  v-model="keyword"
                >
                  <el-select
                    @change="search"
                    style="width: 100px"
                    v-model="selectContent"
                    slot="prepend"
                    placeholder="请选择"
                  >
                    <el-option label="仓库sku" value="storageSku"></el-option>
                    <el-option label="商品sku" value="commoditySku"></el-option>
                  </el-select>
                </el-input>
              </div>
            </div>
          </div>
          <div class="table" v-if="activeName == 'second'">
            <el-table
              height="100%"
              :data="skutableData"
              style="width: 100%"
              cell-style="text-align:center"
              header-cell-style="text-align:center;background:#f7f8fa"
              :show-overflow-tooltip="true"
            >
              <el-table-column prop="storageSku" label="仓库sku" width="width">
              </el-table-column>
              <el-table-column
                prop="commoditySku"
                label="商品sku"
                width="width"
              >
              </el-table-column>
              <el-table-column
                prop="systemStorageName"
                label="规格(cm)"
                width="width"
              >
                <template slot-scope="scoped">
                  <div
                    style="
                      display: flex;
                      justify-content: space-around;
                      align-items: center;
                      padding: 0 20px;
                    "
                  >
                    {{ scoped.row.length }}*{{ scoped.row.width }}*{{
                      scoped.row.height
                    }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="weight" label="重量(kg)" width="width">
              </el-table-column>
              <el-table-column prop="areaName" label="配对状态" width="width">
                <template slot-scope="scoped">
                  {{ scoped.row.status == 0 ? "未配对" : "已配对" }}
                </template>
              </el-table-column>
              <el-table-column prop="areaName" label="操作" width="width">
                <template slot-scope="scoped">
                  <el-button
                    type="text"
                    @click="changeStatus1(scoped.row)"
                    v-if="scoped.row.status == 0"
                    >配对</el-button
                  >
                  <el-button
                    type="text"
                    @click="changeStatus0(scoped.row.id)"
                    v-if="scoped.row.status == 1"
                    >解除配对</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
            <div style="text-align: right; padding-top: 20px">
              <el-pagination
                background
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page.sync="skuObj.current"
                :page-sizes="[10, 20, 30, 40]"
                :page-size="skuObj.pageSize"
                layout="prev, pager, next,sizes"
                :total="total"
              >
              </el-pagination>
            </div>
            <!-- 弹框 -->
            <el-dialog
              :close-on-click-modal="false"
              title="商品SKU配对"
              :visible.sync="matching"
              width="1070px"
              :before-close="matchingClose"
            >
              <div
                style="
                  border-bottom: 1px solid #e6e8ed;
                  margin-bottom: 24px;
                  margin-top: -32px;
                "
              ></div>
              <div>
                <span class="boxp"
                  >{{ this.boxObj.storageSku }}：{{
                    this.boxObj.commoditySku
                  }}</span
                >
                <div class="Box" v-loading="dialogLoading">
                  <div
                    style="width: 180px; float: left; margin: 20px 0 20px 8px"
                  >
                    <el-input
                      placeholder="搜索"
                      suffix-icon="el-icon-search"
                      v-model="input"
                    >
                    </el-input>
                  </div>
                  <div class="tables">
                    <el-table
                      height="100%"
                      :data="boxtableData"
                      style="width: 100%"
                      cell-style="text-align:center"
                      header-cell-style="text-align:center;background:#f7f8fa"
                      :show-overflow-tooltip="true"
                    >
                      <el-table-column
                        prop="storageSku"
                        label="图片"
                        width="width"
                      >
                        <template slot-scope="scoped">
                          <div class="img">
                            <img :src="scoped.row.productImageUrl" alt="" />
                          </div>
                        </template>
                      </el-table-column>
                      <el-table-column prop="sku" label="sku" width="width">
                      </el-table-column>
                      <el-table-column prop="name" label="品名" width="width">
                      </el-table-column>
                      <el-table-column
                        prop="productTypeName"
                        label="类型"
                        width="width"
                      >
                      </el-table-column>
                      <el-table-column label="规格(cm)" width="width">
                        <template slot-scope="scoped">
                          <div
                            style="
                              display: flex;
                              justify-content: space-around;
                              align-items: center;
                              padding: 0 20px;
                            "
                          >
                            {{ scoped.row.productLength }}*{{
                              scoped.row.productWidth
                            }}*{{ scoped.row.productHeight }}
                          </div>
                        </template>
                      </el-table-column>
                      <el-table-column
                        prop="areaName"
                        label="操作"
                        width="width"
                      >
                        <template slot-scope="scoped">
                          <el-button
                            type="text"
                            v-if="scoped.row.state == 0"
                            @click="localchangeStatus(scoped.row.id)"
                            >配对</el-button>
                            <span
                            v-if="scoped.row.state == 1"
                            style="font-size: 12px;"
                            >已配对</span>
                        </template>
                      </el-table-column>
                    </el-table>
                  </div>
                  <div>
                    <div style="text-align: right; padding-top: 20px">
                      <!-- <el-pagination
                          background
                          @current-change="changeCurrent"
                          :current-page.sync="getGoodsInfo.current"
                          layout="prev, pager, next"
                          :total="goodsTotal"
                        >
                        </el-pagination> -->
                    </div>
                  </div>
                </div>
              </div>
              <div slot="footer">
                <el-button @click="matchingClose">取 消</el-button>
              </div>
            </el-dialog>
          </div>
        </div>
      </div>
    </div>
    <!-- 新增 -->
    <el-dialog width="400px" :title="title" :visible.sync="addVisible">
      <el-form
        ref="formInline"
        :inline="true"
        :model="formInline"
        class="demo-form-inline"
        label-position="left"
        label-width="90px"
      >
        <hr />
        <el-form-item label="仓库属性">
          <el-radio-group v-model="shipFromAddress.packageType">
            <el-radio disabled :label="0">本地仓库</el-radio>
            <el-radio disabled :label="1">海外仓库</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="仓库类型">
          <el-radio-group v-model="shipFromAddress.packageTypes">
            <el-radio disabled :label="0">自建</el-radio>
            <el-radio disabled :label="1">第三方</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="第三方仓库" prop="account">
          <el-select
            v-model="formInline.wmsProviderId"
            placeholder="请选择"
            style="width: 247px"
          >
            <el-option
              v-for="item in list"
              :key="item.id"
              :label="item.dicItemValue"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="账 号"
          prop="account"
          :rules="[
            {
              required: true,
              message: '请输入账号',
              trigger: 'blur',
            },
          ]"
        >
          <el-input
            v-model="formInline.account"
            placeholder=""
            style="width: 247px"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="密 码"
          prop="password"
          :rules="[
            {
              required: true,
              message: '请输入密码',
              trigger: 'blur',
            },
          ]"
        >
          <el-input
            v-model="formInline.password"
            placeholder=""
            style="width: 247px"
          ></el-input>
        </el-form-item>
        <div style="text-align: right; margin-top: 30px">
          <el-button @click="addVisible = false">取 消</el-button>
          <el-button
            type="primary"
            @click="addconfirm"
            v-show="this.title == '添加仓库'"
            >确定</el-button
          >
          <el-button
            type="primary"
            @click="editConfirm"
            v-show="this.title == '编辑仓库'"
            >确定</el-button
          >
        </div>
      </el-form>
    </el-dialog>

    <!-- 编辑信息 -->
    <el-dialog width="400px" :title="title" :visible.sync="addVisible">
      <el-form
        ref="formInline"
        :inline="true"
        :model="formInline"
        class="demo-form-inline"
        label-position="left"
        label-width="90px"
      >
        <hr />
        <el-form-item label="仓库属性">
          <el-radio-group v-model="shipFromAddress.packageType">
            <el-radio disabled :label="0">本地仓库</el-radio>
            <el-radio disabled :label="1">海外仓库</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="仓库类型">
          <el-radio-group v-model="shipFromAddress.packageTypes">
            <el-radio disabled :label="0">自建</el-radio>
            <el-radio disabled :label="1">第三方</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="第三方仓库" prop="account">
          <el-select
            v-model="formInline.wmsProviderId"
            placeholder="请选择"
            style="width: 247px"
          >
            <el-option
              v-for="item in list"
              :key="item.id"
              :label="item.dicItemValue"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="账 号"
          prop="account"
          :rules="[
            {
              required: true,
              message: '请输入账号',
              trigger: 'blur',
            },
          ]"
        >
          <el-input
            v-model="formInline.account"
            placeholder=""
            style="width: 247px"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="密 码"
          prop="password"
          :rules="[
            {
              required: true,
              message: '请输入密码',
              trigger: 'blur',
            },
          ]"
        >
          <el-input
            v-model="formInline.password"
            placeholder=""
            style="width: 247px"
          ></el-input>
        </el-form-item>
        <div style="text-align: right; margin-top: 30px">
          <el-button @click="addVisible = false">取 消</el-button>
          <el-button
            type="primary"
            @click="addconfirm"
            v-show="this.title == '添加仓库'"
            >确定</el-button
          >
          <el-button
            type="primary"
            @click="editConfirm"
            v-show="this.title == '编辑仓库'"
            >确定</el-button
          >
        </div>
      </el-form>
    </el-dialog>
    <!-- 导入配对 -->
    <el-dialog
            title="批量导入配对"
            :visible.sync="goodsImportedBox"
            width="27%"
            :before-close="goodsImportedBoxClose">
            
            <div v-loading='dialogLoading' style="margin-top:-20px;line-height:20px;font-size:12px">
              <el-button type="text" @click="downfirst" style="margin-top:15px" plain class="muban">
                    <p>下载配对模板</p>
                </el-button>
                <p>1.支持导入格式为xslx的文件;</p>
                <p>2.模版中的表头不可更改;。</p>
                <p>3.数据记录不可超过500条;数据记录不可超过500条;</p>
                <p>4.确保第三方仓库的SKU是正确的，请从第三方海外仓管理后台导出获得;</p>
                <p>5.如果您不需要在这一步添加配对关系，可以不填写本地SKU; </p>
                <p>6.导入的商品和配对关系若为全新，增加后不影响原有商品和配对关系数据; </p>
                <p>7.导入的商品或配对关系若非全新，增加将覆盖原有商品和配对关系数据;</p>
                <p>8.第三方仓商品编码和SKU、FNSKU对应关系多条时，取第一条记录; </p>
                
                <div style="text-align:center;margin-top:10px">
                    <el-upload
                    style="width:100%"
                    class="upload-demo"
                    :headers="mytoken"
                    :on-success="handleSuccess"
                    :file-list="fileList"
                    drag
                    :action="action"
                    multiple>
                        <i class="el-icon-upload"></i>
                        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                    </el-upload>
                </div>
            </div>
            <div slot="footer">
                <el-button @click="goodsImportedBoxClose">取 消</el-button>
                <el-button type="primary" @click="goodsImportedBoxClose">确 定</el-button>
            </div>
        </el-dialog>
  </div>
</template>
<script>
import {
  getlistPage,
  gettableList,
  getSku,
  changetatus,
  getlocalList,
  detail,
  autoMatch,
  wmsStorageInit,
  add,
  updateStatus,
  updateEdit,
  updateSynStatus,
  delType,
} from "@/api/warehouseSetting";
export default {
  data() {
    return {
      bottom: false,
      goodsImportedBox:false,
      top: false,
      tops: false,
      matching: false,
      title: "",
      total: "",
      pageInfo: {
        current: "",
        pageSize: "",
      },
      shipFromAddress: {
        packageType: 1,
        packageTypes: 1,
      },
      localStorageList: [],
      outerStorageList: [],
      activeName: "first",
      tableData: [],
      isUsed: false,
      options: [],
      leftdetail: {},
      boxObj: {},
      selectContent: "",
      keyword: "",
      skutableData: [],
      boxtableData: [],
      rightDetail: {},
      addVisible: false,
      formInline: {
        account: "",
        password:'',
        wmsProviderId:'',

      },
      skuObj: {
        wmsProviderId: "",
        current: "",
        pageSize: "",
        status: "",
      },
      options: [
        {
          value: "0",
          label: "未配对",
        },
        {
          value: "1",
          label: "已配对",
        },
      ],
    };
  },
  created() {
    this.getData();
    this.getData1();
  },
  mounted() {},
  methods: {
    //页面初始化
    async getData() {
      try {
        const res = await getlistPage();
        if ((Object.is(res.code), 200)) {
          this.localStorageList = res.data.localStorageList;
          this.outerStorageList = res.data.outerStorageList;
        } else {
          this.$message.error(res.message);
        }
      } catch (error) {}
    },
    //弹框本地商品列表
    getlocal() {
      getlocalList().then((res) => {
        this.boxtableData = res.data.values;
      });
    },
    //表格数据
    getTablesku() {},
    handleClick(item) {
      this.leftdetail = item;
      this.bottom = false;
      this.top = true;
      this.tops = false;
    },
    handleClick1(wmsProviderId) {
      this.bottom = true;
      this.top = false;
      this.tops = true;
      this.skuObj.wmsProviderId = wmsProviderId;
      this.search();
      gettableList(wmsProviderId).then((res) => {
        this.loading = false;
        this.tableData = res.data.data;
      });
      detail(wmsProviderId).then((res) => {
        this.rightDetail = res.data;
      });
    },

    //查询
    search() {
      let TempData = "";
      if (this.selectContent == "" || this.keyword == "") {
        TempData = null;
      } else if (this.selectContent == "storageSku") {
        TempData = { storageSku: this.keyword };
        console.log(TempData);
      } else if (this.selectContent == "commoditySku") {
        TempData = { commoditySku: this.keyword };
      }
      getSku({ ...TempData, ...this.skuObj }).then((res) => {
        this.skutableData = res.data.values;
        this.total = res.data.pageInfo.total;
      });
    },
    handleSizeChange(val) {
      this.skuObj.pageSize = val;
      this.search();
    },
    handleCurrentChange(val) {
      this.skuObj.current = val;
      this.search();
    },
    changeStatus0(id) {
      let data = {
        status: 0,
        wmsProductId: id,
      };
      this.$confirm(
        "确定解除配对吗？",
        "解除？",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).then(() => {
            changetatus(data).then((res) => {
                if(res.data.code == 200){
                    this.$message.success(res.data.message);
                    this.search();
                }else{
                    this.$message.error(res.data.message);
                }
                
                
        });
      })
      
    },
    changeStatus1(row) {
      this.boxObj = row;
      this.matching = true;
      this.getlocal();
    },
    localchangeStatus(id) {
      let data = {
        localProductId: id,
        status: 1,
        wmsProductId: this.boxObj.id,
      };
      changetatus(data).then((res) => {
          if(res.data.code == 200){
            this.$message.success(res.data.message);
            this.matching = false;
          }else{
            this.$message.error(res.data.message);
            this.matching = false;
          }
        
        this.search();
      });
    },
    //系统自动配对
    automaticPair() {
      this.$confirm(
        "系统自动配对只对未配对的商品编码生效。第三方海外仓设置的商品编码和系统的SKU是一致时，则自动配对。配对后可手动修改。",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
            let data = {
                wmsProviderId:this.skuObj.wmsProviderId
            }
          autoMatch(data).then((res) => {
              if(res.data.code == 200){
                this.$message.success(res.data.message);
              }else{
                this.$message.error(res.data.message);
              }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    //列表初始化
    async getData1() {
      try {
        const res = await wmsStorageInit();
        if ((Object.is(res.code), 200)) {
          this.list = res.data.data
        } else {
          this.$message.error(res.message);
        }
      } catch (error) {}
    },
    //添加仓库
    addStor() {
      this.addVisible = true;
      this.title = "添加仓库";
    },
   
    addconfirm() {
      let data = {
        wmsProviderId:this.formInline.wmsProviderId,
        account:this.formInline.account,
        password:this.md5(this.formInline.password)
      };
      add(data).then((res) => {
        this.$message.success(res.data.message);
        this.addVisible = false;

      });

    },
    changeMessage(id){
        this.addVisible = true;
        this.title = "编辑仓库"
    },
    editinfo(){},
    changeStatus(id,isUsed){
        let data = {
            id:id,
            isUsed:isUsed
        }
        updateStatus(data).then((res) => {
            this.$message.success(res.data.message);
      });

    },
    //同步状态修改
    changeStatu(synStatus){
        let data = {
            synStatus:synStatus,
            wmsProviderId:this.skuObj.wmsProviderId
        }
        updateSynStatus(data).then((res) => {
            this.$message.success(res.data.message);
      });

    },
    //取消授权
    cancelstatus(){
      // delType().then((res) => {
      //       this.$message.success(res.data.message);
      // });

      this.$confirm(
        "确定要取消授权吗，一旦取消仓库数据将被删除",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          delType(this.skuObj.wmsProviderId).then((res) => {
              if(res.data.code == 200){
                this.$message.success(res.data.message);
              }else{
                this.$message.error(res.data.message);
              }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    goodsImportedBoxClose(){
      this.goodsImportedBox=false
        },
    downfirst(){
      window.location.href='配对模板.xlsx'
        },
  },
};
</script>

<style lang='scss' scoped>
.content {
  width: 100%;
  display: flex;
  height: calc(100% - 68px);
}
.left {
  width: 200px;
  height: 100%;
  background: #fff;
  border-right: 1px solid #e6e8ed;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  overflow: auto;
}
.right {
  width: calc(100% - 210px);
  height: 100%;
  margin-left: 10px;
  padding: 0 20px 20px 20px;
  box-sizing: border-box;
  background: #fff;
  flex: 1;
  display: flex;
  flex-direction: column;
}
.top {
  height: 34px;
  display: flex;
  justify-content: space-between;
}
.top_title {
  display: flex;
  align-items: center;
  font-size: 20px;
  color: #000;
  letter-spacing: 0;
  font-weight: 600;
  margin-bottom: 6px;
}
.tips {
  display: flex;

  min-width: 48px;
  color: #888;
  font-size: 12px;
  line-height: 12px;
  font-weight: 400;
  margin-bottom: 16px;
}
.pp {
  color: #000;
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
  height: 14px;
  border-left: 2px solid #006eff;
  padding-left: 10px;
  margin-top: 20px;
  margin-bottom: 30px;
}
.tips span {
  color: #888;
  display: inline-block;
  margin-right: 10px;
  width: 60px;
  text-align: right;
}
.msg {
  color: #000;
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
  height: 14px;
  border-left: 2px solid #006eff;
  padding-left: 10px;
  margin-bottom: 16px;
  margin-top: 74px;
}
.table {
  height: 700px;
  flex: 1;
  overflow: auto;
  padding: 20px 0 0 0;
}
.shuoming {
  font-size: 12px !important;
  color: #555;
  margin-top: 15px;
}
.sku {
  height: 700px;
  display: flex;
  flex-direction: column;
  position: relative;
  padding-top: 16px;
}
.oo {
  width: 130px !important;
  text-align: left !important;
}
.boxp {
  padding-left: 10px;
  font-size: 12px;
  color: #000;
}
.Box {
  display: flex;
  flex-direction: column;
}
.tables{
    height: 500px;
}
.img img{
    width: 120px;
    height: 80px;
}
</style>